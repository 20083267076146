<template>
  <div class="ml_page">
    <div class="img_box">
      <img src="../../assets/28.png" alt="" />
    </div>
    <div class="img_box">
      <img src="../../assets/29.png" alt="" />
    </div>
    <div class="img_box">
      <img src="../../assets/30.png" alt="" />
      <a :href="'tel:'+tel" class="btn1"></a>
    </div>
    <div class="img_box">
      <img src="../../assets/31.png" alt="" />
      <a :href="'tel:'+tel" class="btn2"></a>
    </div>
    <div class="img_box">
      <img src="../../assets/32.png" alt="" />
      <img src="../../assets/43.jpg" alt="" class="qr_code" />
    </div>

    <div class="safe_area"></div>
    <div class="phone_box">
      <img src="../../assets/36.png" alt="" />
      <a :href="'tel:'+tel">{{tel}}</a>
    </div>

  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tel: "",
    };
  },
  computed: {},
  mounted() {
    this.tel = this.$route.query.tel || "400-888-8888";
  },
  methods: {},
};
</script>
<style lang='scss' scoped>
.ml_page {
  .img_box{
    position: relative;
    img {
      display: block;
      width: 100%;
    }
    .btn1 {
      width: 85%;
      height: 18%;
      position: absolute;
      top: 59%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 22px;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    .btn2 {
      width: 85%;
      height: 8%;
      position: absolute;
      top: 84.5%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      border-radius: 22px;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
    .qr_code{
      width: 160px;
      height: 160px;
      position: absolute;
      top: -40px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }
  
  .safe_area {
    width: 100%;
    height: 60px;
    height: constant(60px + env(safe-area-inset-bottom)); // tabBar原高度（60px改成你项目自己的值）+安全距离,兼容 iOS < 11.2
    height: calc(60px + env(safe-area-inset-bottom)); // 兼容 iOS >= 11.2
    background: #ffffff;
  }
  .phone_box {
    width: 100%;
    height: 60px;
    background: #ffffff;

    padding-bottom: constant(safe-area-inset-bottom); // tabBar留出底部安全距离, 兼容 iOS < 11.2
    padding-bottom: env(safe-area-inset-bottom); // 兼容 iOS >= 11.2

    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    a {
      color: #327fff;
      font-size: 18px;
      font-weight: bold;
      text-decoration: none;
    }
  }
}
</style>